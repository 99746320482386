import React from "react";
import PropTypes from "prop-types";
import { SmallTertiaryButton } from "../Buttons";

const AccessSection = ({ section, language }) => (
  <section className="section py-32">
    {language === "en" ? (
    <>
      <div className="container">
      <h3>Access</h3>
      <p className="max-w-sm">
        Reach us out by e-mail and phone or visit our heartquarter in Tokyo
      </p>
      <div className="md:flex">
        <p className="md:mr-24">
          Hibot
          <br />
          Watanabe Corp. Bldg. 4F
          <br />
          5-9-15 Kita Shinagawa, Shinagawa-ku
          <br />
          141-0001 Tokyo, Japan
        </p>
        <p>
          株式会社ハイボット
          <br />
          〒141-0001
          <br />
          東京都品川区北品川5-9-15
          <br />
          渡辺コーポレーションビル4階
          <div className="mt-5">
            <SmallTertiaryButton
              button={{
                text: "View on map",
                url: `${language === "en" ? "" : "ja"}/about#map`,
              }}
            />
          </div>
        </p>
      </div>
      <p>
        TEL: +81-(3)-5791-7526
        <br />
        FAX: +81-(3)-5791-7527
      </p>
      {/* <p>Contacts | Careers</p> */}
    </div>
    </>
    ) : (
    <>          
    <div className="container">
      <h3>アクセス</h3>
      <p className="max-w-sm">
      お気軽にお問い合わせください。
      </p>
      <div className="md:flex">
      <p className="md:mr-24">
          株式会社ハイボット
          <br />
          〒141-0001
          <br />
          東京都品川区北品川5-9-15
          <br />
          渡辺コーポレーションビル4階
          <div className="mt-5">
            <SmallTertiaryButton
              button={{
                text: "View on map",
                url: `${language === "en" ? "" : ""}about#map`,
              }}
            />
          </div>
        </p>
        <p >
          hibot
          <br />
          Watanabe Corp. Bldg. 4F
          <br />
          5-9-15 Kita Shinagawa, Shinagawa-ku
          <br />
          141-0001 Tokyo, Japan
        </p>
        
      </div>
      <p>
        TEL: +81-(3)-5791-7526
        <br />
        FAX: +81-(3)-5791-7527
      </p>
      {/* <p>Contacts | Careers</p> */}
    </div>
    </>
        )}  

  </section>
);

AccessSection.propTypes = {
  language: PropTypes.string,
  section: PropTypes.object.isRequired,
};

export default AccessSection;
