import React from "react";
import PropTypes from "prop-types";
// import { SmallTertiaryButton } from "../Buttons";
import { toHTML } from "../../utils/utils";

const ClientsSection = ({ section }) => (
  <section className="section py-32">
    <div className="container">
      <div className="lg:flex">
        <div className="lg:border-r lg:border-gray-600 lg:w-full lg:max-w-xs lg:pr-10 mb-12">
          <h3>{section.title}</h3>
          <div
            className="section-body"
            dangerouslySetInnerHTML={{ __html: toHTML(section.body) }}
          ></div>
          {/* <SmallTertiaryButton button={{ text: "Learn more", url: "blog/" }} /> */}
        </div>
        <div className="flex flex-wrap lg:pl-5">
          <img
            src={require("../../images/clients/basf.svg")}
            style={{ height: 80, marginRight:10 }}
            className="m-2"
            alt="Partner logo"
          />
          <img
            src={require("../../images/clients/ebara-e.svg")}
            style={{ height: 60 }}
            className="m-2"
            alt="Partner logo"
          />
          <img
            src={require("../../images/clients/terna.jpg")}
            style={{ height: 40, marginRight:10 }}
            className="m-2"
            alt="Partner logo"
          />
          <div className="w-full md:w-1/3 lg:w-1/3 p-1 mb-8 mr-0">
            <div className="p-1">
              {/* <div className="text-sm font-mono">Co-founder, CEO</div> */}
              <div className="text-xl font-mono lg:ml-8">ST Engineering Aerospace</div>
              {/* <div className="text-xm"> Linkedin </div> */}
            </div>
          </div>
          <div className="w-full md:w-1/3 lg:w-1/3 p-1 mb-10 mr-0">
            <div className="p-1">
              {/* <div className="text-sm font-mono">Co-founder, CEO</div> */}
              <div className="text-xl font-mono">General Electric</div>
              {/* <div className="text-xm"> Linkedin </div> */}
            </div>
          </div>
          <div className="w-full md:w-1/3 lg:w-1/3 p-1 mb-10 mr-0">
            <div className="p-1">
              {/* <div className="text-sm font-mono">Co-founder, CEO</div> */}
              <div className="text-xl font-mono">Boeing</div>
              {/* <div className="text-xm"> Linkedin </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

ClientsSection.propTypes = {
  language: PropTypes.string,
  section: PropTypes.object.isRequired,
};

export default ClientsSection;
