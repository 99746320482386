import React from "react";
import PropTypes from "prop-types";
import ModalContext from "../contexts/ModalContext";

import IosReturnRight from "react-ionicons/lib/IosReturnRight";

export const PrimaryButton = ({ button }) => {
  if (button.modalContent) {
    return (
      <ModalContext>
        {({ openModal }) => (
          <button
            onClick={() => openModal(button.modalContent)}
            className="btn btn-primary"
          >
            {button.text}{" "}
            <IosReturnRight
              className="inline ml-1"
              color="white"
              fontSize="30px"
            />
          </button>
        )}
      </ModalContext>
    );
  } else {
    return (
      <a href={button.url} className="btn btn-primary">
        {button.text}{" "}
        <IosReturnRight className="inline ml-1" color="white" fontSize="30px" />
      </a>
    );
  }
};

PrimaryButton.propTypes = {
  button: PropTypes.object.isRequired,
};

export const SecondaryButton = ({ button }) => {
  if (button.modalContent) {
    return (
      <ModalContext>
        {({ openModal }) => (
          <button
            onClick={() => openModal(button.modalContent)}
            className="btn btn-secondary"
          >
            {button.text}{" "}
            <IosReturnRight
              className="inline ml-1"
              color="white"
              fontSize="30px"
            />
          </button>
        )}
      </ModalContext>
    );
  } else {
    return (
      <a href={button.url} className="btn btn-secondary">
        {button.text}{" "}
        <IosReturnRight className="inline ml-1" color="white" fontSize="30px" />
      </a>
    );
  }
};

SecondaryButton.propTypes = {
  button: PropTypes.object.isRequired,
};

export const TertiaryButton = ({ button }) => {
  if (button.modalContent) {
    return (
      <ModalContext>
        {({ openModal }) => (
          <button
            onClick={() => openModal(button.modalContent)}
            className="btn btn-tertiary"
          >
            {button.text}{" "}
            <IosReturnRight
              className="inline ml-1"
              color="white"
              fontSize="30px"
            />
          </button>
        )}
      </ModalContext>
    );
  } else {
    return (
      <a href={button.url} className="btn btn-tertiary">
        {button.text}{" "}
        <IosReturnRight
          className="inline ml-1"
          color="#FF521C"
          fontSize="30px"
        />
      </a>
    );
  }
};

TertiaryButton.propTypes = {
  button: PropTypes.object.isRequired,
};

export const SmallTertiaryButton = ({ button }) => {
  if (button.modalContent) {
    return (
      <ModalContext>
        {({ openModal }) => (
          <button
            onClick={() => openModal(button.modalContent)}
            className="btn btn-tertiary-sm"
          >
            {button.text}{" "}
            <IosReturnRight
              className="inline ml-1"
              color="white"
              fontSize="30px"
            />
          </button>
        )}
      </ModalContext>
    );
  } else {
    return (
      <a href={button.url} className="btn btn-tertiary-sm">
        {button.text}{" "}
        <IosReturnRight
          className="inline ml-1"
          color="#FF521C"
          fontSize="30px"
        />
      </a>
    );
  }
};

SmallTertiaryButton.propTypes = {
  button: PropTypes.object.isRequired,
};
