import React from "react";
import PropTypes from "prop-types";

const MapSection = () => (
  <section id="map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12973.454265532098!2d139.7344174!3d35.6187627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2eb205e9a80a57e1!2zSGlCb3Qg44OP44Kk44Oc44OD44OI!5e0!3m2!1sen!2sjp!4v1584587319659!5m2!1sen!2sjp"
      width="100%"
      height="600"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullscreen=""
      aria-hidden="false"
      tabIndex="0"
    ></iframe>
  </section>
);

MapSection.propTypes = {
  language: PropTypes.string,
  section: PropTypes.object.isRequired,
};

export default MapSection;
