import React from "react";
import PropTypes from "prop-types";
import { toHTML } from "../../utils/utils";
import ButtonGroup from "../ButtonGroup";

const BodySection = ({ section }) => (
  <section className="section py-32">
    <div className="container">
      <div
        className="section-body max-w-3xl mx-auto"
        dangerouslySetInnerHTML={{ __html: toHTML(section.body) }}
      ></div>
      <div className="max-w-3xl mx-auto">
        <ButtonGroup buttons={section.buttons} />
      </div>
    </div>
  </section>
);

BodySection.propTypes = {
  language: PropTypes.string,
  section: PropTypes.object.isRequired,
};

export default BodySection;
