import React from "react";
import PropTypes from "prop-types";
import ButtonGroup from "../ButtonGroup";
import SolutionGroup from "../SolutionGroup";
import { toHTML } from "../../utils/utils";

const OneColumnSection = ({ section }) => (
  <section className={`section ${section.cssClass}`}>
    {section.backgroundImage && (
      <div
        className="bg-image"
        style={{
          backgroundImage: `url(${section.backgroundImage.childImageSharp.fluid.src})`,
        }}
      ></div>
    )}
    {section.backgroundVideo && (
      <div
        className="bg-video"
        dangerouslySetInnerHTML={{
          __html: section.backgroundVideo,
        }}
      ></div>
    )}
    <div className="bg-overlay" style={{ opacity: 0.7 }}></div>
    <div className="container w-auto sm:w-auto ">
      <h2
        className="xl:w-1/2"
        dangerouslySetInnerHTML={{ __html: section.title }}
      ></h2>
      <div
        className="xl:w-3/4"
        dangerouslySetInnerHTML={{ __html: toHTML(section.body) }}
      ></div>
      <ButtonGroup buttons={section.buttons} />
      <SolutionGroup solutions={section.solutions} />
    </div>
  </section>
);

OneColumnSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default OneColumnSection;
