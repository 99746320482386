import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";

class ProductRoll extends React.Component {
  render() {
    const { language, data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    let filteredPosts = posts.filter(
      (edge) => edge.node.frontmatter.language === language
    );

    return (
      <div className="max-w-4xl mx-auto">
        {filteredPosts &&
          filteredPosts.map(({ node: post }) => (
            <div key={post.id} className="md:flex">
              <div className="md:w-1/2">
                <Link className="block" to={post.fields.slug}>
                  <div
                    className="cover-image aspect-ratio-square"
                    style={{
                      backgroundImage: `url(${post.frontmatter.featuredimage.childImageSharp.fluid.src})`,
                    }}
                  ></div>
                </Link>
              </div>
              <div className="md:w-1/2 p-5">
                <h2 className="mb-0">{post.frontmatter.title}</h2>
                <h3>{post.frontmatter.description}</h3>
                <p>{post.excerpt}</p>
                <Link className="btn btn-tertiary-sm" to={post.fields.slug}>
                  Learn more
                </Link>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

ProductRoll.propTypes = {
  language: PropTypes.string,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const ProductRollQuery = ({ language }) => (
  <StaticQuery
    query={graphql`
      query ProductRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "product" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                language
                title
                description
                url
                templateKey
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <ProductRoll data={data} count={count} language={language} />
    )}
  />
);

ProductRollQuery.propTypes = {
  language: PropTypes.string,
};

export default ProductRollQuery;
