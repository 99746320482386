import React from "react";
import PropTypes from "prop-types";
import { toHTML } from "../../utils/utils";

const HtmlSection = ({ section }) => (
  <section className="section">
    <div dangerouslySetInnerHTML={{ __html: toHTML(section.body) }}></div>
  </section>
);

HtmlSection.propTypes = {
  language: PropTypes.string,
  section: PropTypes.object.isRequired,
};

export default HtmlSection;
