import React from "react";
import PropTypes from "prop-types";
import ButtonGroup from "../ButtonGroup";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
import { toHTML } from "../../utils/utils";

const TwoColumnSection = ({ section }) => (
  <section className={`section ${section.cssClass}`}>
    <div className="container">
      <div className="md:flex items-center section-columns">
        <div className="md:w-1/2 md:p-5">
          <PreviewCompatibleImage
            imageInfo={{
              image: section.image,
              alt: section.title,
            }}
          />
        </div>
        <div className="md:w-1/2 md:p-5">
          <h2 dangerouslySetInnerHTML={{ __html: section.title }}></h2>
          <div dangerouslySetInnerHTML={{ __html: toHTML(section.body) }}></div>
          <ButtonGroup buttons={section.buttons} />
        </div>
      </div>
    </div>
  </section>
);

TwoColumnSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default TwoColumnSection;
