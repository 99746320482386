import React from "react";
import PropTypes from "prop-types";
import ProductRoll from "../ProductRoll";

const ProductsSection = ({ language, section }) => (
  <section className="section py-20">
    <div className="container">
      <ProductRoll language={language} />
    </div>
  </section>
);

ProductsSection.propTypes = {
  language: PropTypes.string,
  section: PropTypes.object.isRequired,
};

export default ProductsSection;
