import React from "react";
import PropTypes from "prop-types";
import ButtonGroup from "../ButtonGroup";
import SolutionGroup from "../SolutionGroup";
import { toHTML } from "../../utils/utils";

const HeroSection = ({ section }) => (
  <section
    className={`section bg-black text-white min-h-screen flex flex-col justify-center ${section.cssClass}`}
  >
    {section.backgroundImage && (
      <div
        className="bg-image"
        style={{
          backgroundImage: `url(${section.backgroundImage.childImageSharp.fluid.src})`,
        }}
      ></div>
    )}
    {section.backgroundVideo && (
      <div
        className="bg-video"
        dangerouslySetInnerHTML={{
          __html: section.backgroundVideo,
        }}
      ></div>
    )}
    <div className="bg-overlay" style={{ opacity: 0.7 }}></div>
    <div className="container">
      <h1 dangerouslySetInnerHTML={{ __html: section.title }}></h1>
      <div
        className="max-w-3xl inline-block"
        dangerouslySetInnerHTML={{ __html: toHTML(section.body) }}
      ></div>
      <ButtonGroup buttons={section.buttons} />
      <SolutionGroup solutions={section.solutions} />
    </div>
  </section>
);

HeroSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default HeroSection;
