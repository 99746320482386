import React from "react";
import PropTypes from "prop-types";

const QuoteSection = ({ section }) => (
  <section className={`section ${section.cssClass}`}>
    <div className="container">
      <div className="max-w-3xl mx-auto pt-56 pb-24">
        <blockquote
          className="text-center text-4xl font-regular mb-24"
          dangerouslySetInnerHTML={{ __html: section.title }}
        ></blockquote>
        <div className="text-center md:text-right">{section.body}</div>
      </div>
    </div>
  </section>
);

QuoteSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default QuoteSection;
