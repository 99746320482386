import React from "react";
import PropTypes from "prop-types";
import Section from "./Section";
import Scrollspy from "react-scrollspy";

const Sections = ({ sections, language }) => {
  if (!sections) {
    return null;
  }

  const menuSections = sections.filter(
    (section) => section.menuTitle != "" && section.menuTitle != null
  );

  return (
    <>
      {sections.map((section) => (
        <>
          <div id={section.menuTitle} key={section}></div>
          <Section section={section} language={language} />
        </>
      ))}
      <Scrollspy
        className="sections-menu"
        items={menuSections.map((section) => section.menuTitle)}
        currentClassName="is-current"
        offset={100}
      >
        {menuSections.map((section) => (
          <li key={section}>
            <a
              href={`#${section.menuTitle}`}
              key={section}
              className="section-menu-item"
            >
              {section.menuTitle}
            </a>
          </li>
        ))}
      </Scrollspy>
    </>
  );
};

Sections.propTypes = {
  sections: PropTypes.array,
  language: PropTypes.string,
};

export default Sections;
