import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton, SecondaryButton, TertiaryButton } from "./Buttons";
import _ from "lodash";

const Components = {
  PrimaryButton: PrimaryButton,
  SecondaryButton: SecondaryButton,
  TertiaryButton: TertiaryButton,
};

const Button = ({ button }) => {
  const DynamicButton = Components[button.type];
  return <DynamicButton button={button} />;
};

Button.propTypes = {
  button: PropTypes.object.isRequired,
};

const ButtonGroup = ({ buttons }) => {
  if (_.isEmpty(buttons)) {
    return null;
  }

  return (
    <div className="btn-group">
      {buttons &&
        buttons.map((button) => <Button key={button} button={button} />)}
    </div>
  );
};

ButtonGroup.propTypes = {
  buttons: PropTypes.array.isRequired,
};

export default ButtonGroup;
