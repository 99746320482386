import React from "react";
import PropTypes from "prop-types";

import HeroSection from "./sections/HeroSection";
import OneColumnSection from "./sections/OneColumnSection";
import TwoColumnSection from "./sections/TwoColumnSection";
import QuoteSection from "./sections/QuoteSection";
import NewsSection from "./sections/NewsSection";
import BodySection from "./sections/BodySection";
import GallerySection from "./sections/GallerySection";
import PartnersSection from "./sections/PartnersSection";
import TeamSection from "./sections/TeamSection";
import ClientsSection from "./sections/ClientsSection";
import AccessSection from "./sections/AccessSection";
import MapSection from "./sections/MapSection";
import HtmlSection from "./sections/HtmlSection";
import ProductsSection from "./sections/ProductsSection";

const Components = {
  HeroSection: HeroSection,
  OneColumnSection: OneColumnSection,
  TwoColumnSection: TwoColumnSection,
  QuoteSection: QuoteSection,
  NewsSection: NewsSection,
  BodySection: BodySection,
  GallerySection: GallerySection,
  PartnersSection: PartnersSection,
  TeamSection: TeamSection,
  ClientsSection: ClientsSection,
  AccessSection: AccessSection,
  MapSection: MapSection,
  HtmlSection: HtmlSection,
  ProductsSection: ProductsSection,
};

const Section = ({ section, language }) => {
  const DynamicSection = Components[section.type];
  return <DynamicSection section={section} language={language} />;
};

Section.propTypes = {
  section: PropTypes.object.isRequired,
  language: PropTypes.string,
};

export default Section;
