import React from "react";
import PropTypes from "prop-types";

const TeamSection = ({ section, language }) => (
  <section className="section py-32">
    <div className="container">
      <h3>{section.title}</h3>
      <div className="flex flex-wrap">
        {language === "en" ? (
          <>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/hero.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="text-sm font-mono">Co-founder, CEO</div>
                <div className="text-xl">Michele Guarnieri</div>
                {/* <div className="text-xm"> Linkedin </div> */}
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">CFO</div>
                <div className="text-xl">Takumi Sato</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">External Board Member</div>
                <div className="text-xl">Tomokuni Takayama</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">External Board Member</div>
                <div className="text-xl">Kazuhito Tsunashima</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">Auditor</div>
                <div className="text-xl">Hiroshi Oki</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">Auditor</div>
                <div className="text-xl">Yusuke Kato</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">
                  Co-founder, Executive Officer
                </div>
                <div className="text-xl">Paulo Debenest</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">Executive Officer</div>
                <div className="text-xl">Hiroya Yamada</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">Executive Officer</div>
                <div className="text-xl">Peter Sommer</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">Executive Officer</div>
                <div className="text-xl">Tetsu Azegami</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">Executive Officer</div>
                <div className="text-xl">Takeru Yamamoto</div>
              </div>
            </div>
          </>
        ) : (
          // Japanese names 
          <>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/hero.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="text-sm font-mono"> 共同創業者、CEO</div>
                <div className="text-xl">ミケレ グアラニエリ</div>
                {/* <div className="text-xm"> Linkedin </div> */}
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">CFO</div>
                <div className="text-xl">佐藤 匠</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">社外取締役</div>
                <div className="text-xl">高山 朝邦</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">社外取締役</div>
                <div className="text-xl">綱島 和仁</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">監査役</div>
                <div className="text-xl">大木 浩</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">監査役</div>
                <div className="text-xl">加藤 祐助</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">
                共同創業者、執行役員
                </div>
                <div className="text-xl">パウロ デベネスト</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">執行役員</div>
                <div className="text-xl">山田 浩也</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">執行役員</div>
                <div className="text-xl">ピーター サマー</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">執行役員 </div>
                <div className="text-xl">畔上 哲男</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">執行役員 </div>
                <div className="text-xl">⼭本 猛</div>
              </div>
            </div>
          </>
        )}
      </div>
      <h3>{language === "en" ? "Advisory Board":"アドバイザリーボード"}</h3>
      <div className="flex flex-wrap">
        {language === "en" ? (
          <>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">
                  Co-founder, Emeritus Technical Advisor
                </div>
                <div className="text-xl">Shigeo Hirose</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">
                  Consultant
                </div>
                <div className="text-xl">John O'Brien</div>
              </div>
            </div>
          </>
          ) : (
          <>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">
                共同創業者、名誉技術顧問
                </div>
                <div className="text-xl">広瀬 茂男</div>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-1 mb-10">
              {/* <div
            className="cover-image aspect-ratio-square"
            style={{
              backgroundImage: `url(${require("../../images/members/jurica.jpg")})`,
            }}
          ></div> */}
              <div className="p-1">
                <div className="font-mono text-sm">
                顧問
                </div>
                <div className="text-xl">ジョン オブライエン</div>
              </div>
            </div>
          </>)}
      </div>
    </div>
  </section>
);

TeamSection.propTypes = {
  language: PropTypes.string,
  section: PropTypes.object.isRequired,
};

export default TeamSection;
