import React from "react";
import PropTypes from "prop-types";
// import { SmallTertiaryButton } from "../Buttons";
import { toHTML } from "../../utils/utils";

const PartnersSection = ({ section }) => (
  <section className="section py-32">
    <div className="container">
      <div className="lg:flex">
        <div className="lg:border-r lg:border-gray-600 lg:w-full lg:max-w-xs lg:pr-10 mb-12">
          <h3>{section.title}</h3>
          <div
            className="section-body"
            dangerouslySetInnerHTML={{ __html: toHTML(section.body) }}
          ></div>
          {/* <SmallTertiaryButton button={{ text: "Learn more", url: "/blog/press" }} /> */}
        </div>
        <div className="flex flex-wrap lg:pl-5">
          <img
            src={require("../../images/clients/mirai.svg")}
            style={{ height: 60, width: "auto", marginRight: 40 }}
            className="block"
            alt="Partner logo"
          />
          <img
            src={require("../../images/clients/ebara-e.svg")}
            style={{ height: 60, width: "auto", marginRight: 10, marginTop: 0 }}
            className="m-2"
            alt="Partner logo"
          />
          <img
            src={require("../../images/clients/fuyo.svg")}
            style={{ height: 40, width: "auto", marginRight: 40, marginTop: 10 }}
            className="block"
            alt="Partner logo"
          />
          <img
            src={require("../../images/clients/takahata.svg")}
            style={{ height: 40, width: "auto", marginRight: 40, marginTop: 10 }}
            className="block"
            alt="Partner logo"
          />
          <img
            src={require("../../images/clients/tppt.svg")}
            style={{ height: 38, width: "auto", marginRight: 40, marginTop: 30 }}
            className="block"
            alt="Partner logo"
          />
          <img
            src={require("../../images/clients/mucap.png")}
            style={{ height: 46, width: "auto", marginRightt: 40, marginTop: 30 }}
            className="block"
            alt="Partner logo"
          />
          
          <img
            src={require("../../images/clients/LiNKX_logo-01.png")}
            style={{ height: 50, width: "auto", marginRight: 40, marginTop: 30 }}
            className="block"
            alt="Partner logo"
          />
        </div>
      </div>
    </div>
  </section>
);

PartnersSection.propTypes = {
  language: PropTypes.string,
  section: PropTypes.object.isRequired,
};

export default PartnersSection;
