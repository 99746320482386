import React from "react";
import PropTypes from "prop-types";
import { toHTML } from "../../utils/utils";

const GallerySection = ({ section }) => (
  <section className="section py-16">
    <div className="gallery">
      {section.images &&
        section.images.map((slide) => (
          <div key={slide} className="gallery-slide">
            <img
              className="gallery-slide-img"
              src={slide.image.childImageSharp.fluid.src}
            />
            <div
              className="p-2"
              dangerouslySetInnerHTML={{ __html: toHTML(slide.caption) }}
            ></div>
          </div>
        ))}
    </div>
  </section>
);

GallerySection.propTypes = {
  language: PropTypes.string,
  section: PropTypes.object.isRequired,
};

export default GallerySection;
