import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const SolutionGroup = ({ solutions }) => {
  if (_.isEmpty(solutions)) {
    return null;
  }

  return (
    <div className="mt-20 flex flex-wrap">
      {solutions &&
        solutions.map((solution) => (
          <div
            key={solution}
            className="dashboard-item w-full md:w-1/2 lg:w-1/4 p-4 flex"
          >
            <img
              className="dashboard-item-icon"
              alt={solution.text}
              src={solution.icon.childImageSharp.fluid.src}
            />
            <div className="text-left mt-2 ml-3">
              <h5 dangerouslySetInnerHTML={{ __html: solution.text }}></h5>
            </div>
          </div>
        ))}
    </div>
  );
};

SolutionGroup.propTypes = {
  solutions: PropTypes.array.isRequired,
};

export default SolutionGroup;
